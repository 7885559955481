import React from 'react'
import { Container, Row, Col, Card, Button } from 'react-bootstrap'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import {
  textStyle,
  buttonStyle,
  containerImageCover,
  backgroundStyle,
  backgroundPosition,
  mapSubModuleFields,
} from '../../utils'
import { GTM } from '../../constants'
import { MappedImage } from '../Image'
import parse from 'html-react-parser'
import styled from '@emotion/styled'
import { useSearchParams } from '../../libs/hooks'

const IframeContainer = styled.span`
  padding-bottom: 56.25%;
  position: relative;
  display: block;
  width: 100%;

  > iframe {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`

export const Hero = ({
  backgroundImage,
  orgId,
  heading,
  subHeading,
  description,
  htmlText,
  richtext,
  payerLink,
  buttonLink,
  buttonText,
  brandingImage,
  featuredImage,
  style,
  subModules,
  videoUrl,
}) => {
  const url = useSearchParams(buttonLink, orgId, payerLink)
  const imageLink = !payerLink ? buttonLink : url

  return (
    <Container
      className="p-lg-3 p-md-2 outer hero"
      fluid
      css={[
        containerImageCover(backgroundImage?.file.url),
        backgroundPosition(style?.backgroundPosition),
        backgroundStyle(style?.backgroundColor),
      ]}
    >
      <Row className="hero-outer p-4 mx-auto">
        <Col
          md={12}
          lg={6}
          className="d-flex my-3 justify-content-center flex-column mx-auto hero-column-container"
        >
          <div className="hero-content-wrapper m-auto">
            {heading && (
              <h1
                className="my-lg-4 my-md-3 module-heading"
                css={textStyle(style?.headingColor)}
              >
                {parse(heading)}
              </h1>
            )}
            {subHeading && (
              <div
                className="my-lg-4 my-md-3 hero-subheading"
                css={textStyle(style?.subHeadingColor)}
              >
                {parse(subHeading)}
              </div>
            )}
            <div
              className="my-lg-4 my-md-3 module-desc"
              css={textStyle(style?.textColor)}
            >
              {description && (
                <div
                  className="module-desc-inner"
                  dangerouslySetInnerHTML={{ __html: description.description }}
                ></div>
              )}
              {htmlText && parse(htmlText.htmlText)}
            </div>
            <div className="hero-button-wrapper-outer">
              <div className="hero-button-wrapper-inner">
                {buttonText && (
                  <a
                    href={url}
                    id={orgId + '-' + GTM.GET_STARTED}
                    className="my-4 site-btn"
                    css={buttonStyle(
                      style?.buttonColor,
                      style?.buttonTextColor
                    )}
                    aria-label={`${buttonText}`}
                  >
                    {buttonText}
                  </a>
                )}
              </div>
            </div>
            {brandingImage && (
              <div className="branding-img">
                {<MappedImage image={brandingImage} />}
              </div>
            )}
            {richtext && richtext.raw && (
              <div className="my-2 branding-text">
                {documentToReactComponents(JSON.parse(richtext.raw))}
              </div>
            )}
          </div>
        </Col>
        <Col className="d-flex justify-content-center mx-auto align-items-center">
          {videoUrl && (
            <IframeContainer>
              <iframe
                title="Unique Title 002"
                src={videoUrl}
                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                frameBorder="0"
                allowFullScreen
              ></iframe>
            </IframeContainer>
          )}
          {featuredImage && (
            <div className="ft-img-wrapper">
              <a href={imageLink} className="my-5 featured-image">
                <MappedImage image={featuredImage} alt={'featured-img'} />
              </a>
            </div>
          )}
        </Col>
        <Row>
          {subModules?.map((item, idx) => (
            <Col className="my-3" key={`${item?.richText}-${idx}`}>
              <Card className="mx-auto site-card d-flex flex-column h-100">
                <Card.Body>
                  <Card.Title
                    className=""
                    css={backgroundStyle(item?.style?.backgroundColor)}
                  >
                    <h2
                      className="py-3 text-center"
                      css={textStyle(item?.style?.headingColor)}
                    >
                      {item?.heading}
                    </h2>
                  </Card.Title>
                  <Card.Text className="text-left">{item?.richText}</Card.Text>
                  {item?.button && (
                    <Button variant="primary">{item?.button}</Button>
                  )}
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Row>
    </Container>
  )
}

export const mapHeroProps = props => mapSubModuleFields(props)
